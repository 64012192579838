<template>
  <main>
    <div class="horiz-container">
      <h1 class="page-title">ДОГОВОР-ОФЕРТА АГЕНТСКИЙ</h1>
      <p class="offer-subtitle">Редакция 1.0 от «01» февраля 2023 года</p>
      <p></p>
      <p>Общество с ограниченной ответственностью «ИВЕНТ ХАБ» ИНН 7728470702 КПП 772801001 ОГРН 1197746327217, именуемое
        в дальнейшем «Агент» с одной стороны и физическое лицо, плательщик налога на профессиональный доход, либо
        зарегистрированное в качестве индивидуального предпринимателя, и оказывающее на профессиональной основе
        консультационные услуги в сфере IT, цифрового дизайна или иной сфере именуемое в дальнейшем «Принципал», каждый
        по отдельности именуется «Сторона», а вместе именуются «Стороны», заключили настоящий Агентский Договор (далее
        по тексту – «Договор») на следующих условиях.</p>
      <p>Договор является публичной офертой.</p>
      <p></p>
      <h2>1. ОСНОВНЫЕ ПОНЯТИЯ</h2>
      <p>1.1. Агент – ООО «ИВЕНТ ХАБ», являющееся стороной Договора и предоставляющее Принципалу услуги в порядке и на
        условиях Договора.</p>
      <p>1.2. Принципал – лицо, являющееся плательщиком налога на профессиональный доход либо зарегистрированное в
        качестве индивидуального предпринимателя лицо, осуществляющее реализацию услуг дистанционным способом
        посредством Сайта в качестве Ментора или в качестве Специалиста и совершившее безусловный акцепт Договора, а
        также имеющее и осуществляющее правомерный доступ к Сайту и его сервисам, обладающее достаточными правами для
        совершения законных сделок на Сайте (включая обладание дееспособностью для совершения соответствующей сделки), и
        не имеющее каких-либо ограничений в соответствии с законодательством Российской Федерации. Принципал проходит
        регистрацию на Сайте в качестве Ментора, то есть, лица, являющегося специалистом, обладающим образованием,
        знаниями и опытом работы от трех лет в сфере IT, либо Digital, либо в иной профессии, и намеренным оказывать
        посредством Сайта консультационные услуги по проведению занятий для Клиентов. </p>
      <p>1.3. Клиент (Менти или Заказчик) – физическое лицо, потребитель услуг Принципала, получающее и оплачивающее
        услуги Принципала посредством Сайта.</p>
      <p>1.4. Занятие – лекция, проводимая Принципалом (Ментором) для Клиента (Менти) дистанционным способом с помощью
        функционала Сайта, в том числе с использованием видеосвязи, чата и иных доступных средств. </p>
      <p>1.5. Сайт - сайт, расположенный в сети Интернет по адресу <a href="https://mentoring.digital/">https://mentoring.digital/</a>.
      </p>
      <p>1.6. Сайт включает в себя интерфейс, программное обеспечение, текст, графические изображения, иллюстрации, и
        иные элементы (инструменты, алгоритмы, способы), необходимые для надлежащего функционирования Сайта и
        позволяющие Принципалу размещать, осуществлять поиск и просмотр информации, связанной с реализацией им услуг, а
        также любые иные возможности и функции, доступные на Сайте. Ни одно из положений Договора не может трактоваться
        как передача исключительных прав на Сайт (его отдельные элементы) Принципалу.</p>
      <p>1.7. Открытая лицензия - лицензионный договор, предоставляющий Принципалу простую (неисключительную) лицензию
        использование сервисами Сайта, исключительные права на которую принадлежат Агенту. Любое лицо имеет возможность
        ознакомиться с описанием Сайта перед началом его использования. Открытая лицензия является договором
        присоединения, условия которой определены в тексте Договора и принимаются другой стороной (Принципалом) путем
        присоединения к предложенному Договору в целом, без каких-либо оговорок и исключений. Договор представляет собой
        предложение правообладателя (Агента) любому лицу на изложенных в Договоре условиях, заключить лицензионный
        договор в упрощенном порядке по правилам, установленным ст. 1286.1 Гражданского кодекса РФ на безвозмездной
        основе. Акцептом условий настоящей открытой лицензии считается регистрация Принципала на Сайте. В этом случае
        письменная форма договора считается соблюденной.</p>
      <p>1.8. Услуги – консультационные услуги, оказываемые Принципалом для Клиента по договору оказания
        консультационных услуг, размещенному на Сайте. Услуги оказываются в форме проведения дистанционных занятий в
        сети Интернет посредством Сайта; а также услуги Специалиста по реализации проекта Заказчика, размещенного на
        Сайте.</p>
      <p>1.9. Регистрация — совокупность действий Принципала в соответствии с инструкциями, указанными в Договоре,
        включая предоставление сведений, позволяющих идентифицировать лицо (простая электронная подпись), создания
        уникального логина и пароля, а также иной информации, с использованием специальной формы интерфейса Сайта в
        целях формирования Личного Кабинета и получения доступа к отдельным сервисам Сайта.</p>
      <p>1.10. Регистрационная форма – специальная форма интерфейса Сайта, которую Принципал самостоятельно заполняет в
        целях Регистрации на Сайте.</p>
      <p>1.11. Личный кабинет – персональный закрытый раздел на Сайте, доступ к которому имеет Принципал (при
        регистрации на Сайте в качестве Ментора/Специалиста) при вводе своего логина и пароля. Личный кабинет содержит
        данные Принципала на Сайте и предназначен для хранения информации о Принципале, для проведения занятий, обмена
        сообщениями и прочую информацию.</p>
      <p>1.12. Профиль - раздел Сайта, на котором размещены общедоступные сведения о Принципале, отзывы Клиентов,
        количество проведенных занятий и т.д. Принципал (Ментор/Специалист) самостоятельно заполняет данные своего
        Профиля, следит за их актуальностью и своевременно вносит в них изменения. </p>
      <p>1.13. Личный счет - раздел Личного кабинета, в котором отражаются сведения о расчетных операциях и поступивших
        Принципалу от Клиентов денежных средствах. В этом разделе содержится информация, составляющая отчет Агента по
        исполнению обязательств по Договору.</p>
      <p>1.14. Платеж – денежные средства, используемые для расчетов, уплачиваемые Клиентом в пользу Принципала за
        приобретаемые посредством Сайта услуги Принципала.</p>
      <p>1.15. Агентское вознаграждение - вознаграждение за исполнение Агентом поручения Принципала по Договору,
        определяемое в абсолютном или процентном соотношении от Платежа. Размер Агентского вознаграждения определяется
        по правилам раздела 4 Договора.</p>
      <p></p>
      <h2>2. ПРЕДМЕТ ДОГОВОРА </h2>
      <p>2.1. По Договору Агент за вознаграждение принимает на себя обязательство от имени, по поручению и за счет
        Принципала выполнить поиск Клиентов и организовать взаимодействие Клиентов с Принципалом посредством Сайта, а
        также выполнить иные действия, предусмотренные Договором, а Принципал выплачивает Агенту агентское
        вознаграждение в размере, установленном в разделе 4 Договора.</p>
      <p>2.2. Агент оказывает Заказчику услуги по предоставлению информации о контактных данных Специалиста (номер
        телефона, адрес электронной почты, никнейм в социальных сетях). Стоимость услуг по предоставлению информации,
        указанной в настоящем пункте отражена в Профиле Специалиста, при запросе Заказчиком Услуги по реализации
        проекта.</p>
      <p>2.3. Агент предоставляет Принципалу открытую лицензию на безвозмездной основе с целью использования Сайта для
        заключения Принципалом договоров на оказание консультационных услуг с Клиентами; проведения Занятий, либо
        участия в проекте по договорам оказания консультационных услуг. Договор определяет порядок и условия
        предоставления Агентом и использования Принципалом Сайта, в целях, определенных Договором, в том числе,
        устанавливает условия и порядок осуществления Принципалом реализации Услуг посредством Сайта и его сервисов, а
        также регулирует права и обязанности Агента и Принципала, возникающие в связи с использованием Сайтом.</p>
      <p>2.4. Принципал осуществляет акцепт Договора при регистрации на Сайте в качестве Ментора или Специалиста,
        нажимая на кнопку «Продолжить», тем самым соглашаясь с условиями Оферты (Агентского договора) и Политикой
        Конфиденциальности.</p>
      <p>2.5. Лицо, намеренное заключить Договор в качестве Принципала, обязано ознакомиться с информацией на Сайте с
        условиями работы Сайта, условиями Договора, требованиями к Ментору, требования к Специалисту. В случае согласия
        с размещенным на Сайте Договором, политикой конфиденциальности, договором оказания консультационных услуг, такое
        лицо заполняет соответствующую анкету, размещенную на Сайте, в которой указывает достоверные и актуальные
        сведения о себе. </p>
      <p>2.6. Профиль Принципала публикуется на Сайте в течение 5 (пяти) рабочих дней после проведения Агентом оценки
        предоставленных Принципалом сведений, его навыков в качестве Ментора/Специалиста. Проверка Принципала проводится
        течение 5 (пяти) рабочих дней с даты регистрации Принципала на Сайте в качестве Ментора/Специалиста. Принципал,
        профиль которых опубликован на Сайте и включен в список Менторов/Специалистов, получает возможность заключать
        договоры оказания консультационных услуг с Клиентами.</p>
      <p>2.7. Принципал обязуется самостоятельно следить за изменениями Договора путем периодического ознакомления с
        актуальной редакцией на Сайте. Принципал самостоятельно отвечает за любые последствия, наступившие в связи с не
        ознакомлением с Договором. Если Принципал не согласен с каким-либо положением Договора, он должен
        незамедлительно прекратить использование Сайтом, предварительно уведомив об этом Агента путем направления
        последнему письма на адрес электронной почты, указанный в разделе 11 Договора, при этом выполнив все свои
        обязательства перед Клиентами по уже оплаченным и/или заключенным договорам на оказание консультационных
        услуг.</p>
      <h2>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
      <p>3.1. Агент обязуется:</p>
      <p>3.1.1. Оказывать техническую поддержку Принципала в связи с использованием Сайта;</p>
      <p>3.1.2. Предоставить путем размещения на Сайте сервиса для расчетов между Клиентом и Принципалом за оказанные
        Принципалом Услуги, а также для перечисления Агентского вознаграждения по Договору. </p>
      <p>3.1.3. Организовать подключение Принципала к Сервису Сайта путем предоставления возможности создания на Сайте
        Личного кабинета и Профиля Принципала;</p>
      <p>3.1.4. Предоставить Принципалу возможность пользования Личным кабинетом, сервисами Сайта, включая возможность
        размещения на Сайте информации об Услугах и предложениях для Клиентов.</p>
      <p>3.1.5. В случаях, установленных в Договоре, разбирать в качестве арбитра споры между Принципалом и
        Клиентом.</p>
      <p>3.1.6. Предоставить Принципалу возможность заключать договоры оказания консультационных услуг с Клиентами
        исключительно по форме, разработанной Агентом и размещенной на Сайте.</p>
      <p>3.1.7. Производить расчет Агентского вознаграждения и денежных средств, причитающихся Принципалу по договорам
        оказания консультационных услуг, включая пересчет оплаты услуг Принципала случаях отказа Клиента от занятия
        (п.7.5, п.7.6 Договора).</p>
      <p>3.1.8. Предоставлять Отчеты Агента Принципалу за те месяцы, в которые Клиентами осуществлялись перечисления
        денежных средств для оплаты Услуг Принципала;</p>
      <p>3.1.9. Осуществлять техническую поддержку Клиентов по вопросам, связанным с осуществлением расчетов и
        реализацией услуг Принципала. </p>
      <p>3.2. Принципал обязуется:</p>
      <p>3.2.1. Использовать Сайт исключительно в целях размещения информации о себе для Клиентов с целью заключения с
        последними договоров на оказание консультационных услуг и последующего взаимодействия с Клиентами.</p>
      <p>3.2.2. Осуществлять взаимодействие с Клиентом только посредством Сайта, без использования иных сервисов и
        способов коммуникаций.</p>
      <p>3.2.3. Использовать для взаимоотношений с Клиентом исключительно форму договора оказания консультационных
        услуг, разработанную Агентом и размещенную на Сайте. Использование другого договора, кроме указанной в данном
        пункте формы, для взаимодействия Принципала с Клиентом запрещено. Внесение изменений в договор оказания
        консультационных услуг возможно только с предварительного согласия Агента.</p>
      <p>3.2.4. Соблюдать расписание Занятий, согласованное с Клиентом;</p>
      <p>3.2.5. Оказывать консультационные услуги по проведению Занятий лично, в опрятном внешнем виде, при отсутствии
        факторов мешающих Клиенту воспринимать информацию (посторонний шум, прием пищи, разговор по телефону,
        нестабильный интернет, наличие неоднократных перебоев в сети, плохое качество интернет-соединения и пр.). Не
        допускается: опоздание на Занятие, неявка на Занятие, употребление спиртных, наркотических и токсических веществ
        непосредственно до и во время Занятий; грубое общение с Пользователем, оскорбление чести и достоинства, угроза
        жизни и здоровью и т.д. При выявлении любого из указанных в настоящем пункте нарушений денежные средства за
        Занятие подлежат возврату Клиенту, при этом Принципал остается обязанным уплатить Агенту вознаграждение.</p>
      <p>3.2.6. Предоставлять доступ (направить ссылку) Агенту к видеозаписи Занятия по запросу Агента.</p>
      <p>3.2.7. Своевременно информировать об изменении своих личных данных, указанных при регистрации на Сайте, а также
        обо всех существенных изменениях, влияющих на исполнение Договора. Агент вправе потребовать от Принципала
        предоставления скан-копии документов, подтверждающих изменение личных данных Принципала. Указанные скан-копии
        должны быть предоставлены Принципалом не позднее, чем в течение 3 (трех) календарных дней с момента получения от
        Агента соответствующего запроса.</p>
      <p>3.2.8. По запросу Агента в течение 3 (трех) календарных дней предоставлять информацию и документы,
        подтверждающие надлежащее исполнение обязательств перед Клиентами. Запрос может быть направлен посредством
        функционала Сайта, либо по электронной почте, указанной Принципалом при регистрации.</p>
      <p>3.2.9. Предоставлять Агенту всю достоверную и необходимую ему информацию для исполнения обязательств по
        Договору.</p>
      <p>3.2.10. Обязуется самостоятельно уплачивать налоги и подавать необходимые документы в контролирующие
        органы.</p>
      <p>3.2.11. В случае утраты статуса индивидуального предпринимателя, либо плательщика налога не профессиональный
        доход незамедлительно уведомить об этом Агента и прекратить оказание услуг до восстановления утраченного статуса
        (постановки на налоговый учет).</p>
      <p>3.2.12. Самостоятельно и надлежащим образом исполнять обязательства перед Клиентами по договорам оказания
        консультационных услуг, а также самостоятельно урегулировать любые связанные с такими обязательствами претензии
        Клиентов, включая претензии по количеству и качеству Услуг. </p>
      <p>3.3. Агент вправе:</p>
      <p>3.3.1. Направлять Принципалу уведомления, запросы, сообщения, в т.ч. рекламного характера.</p>
      <p>3.3.2. Удерживать денежные средства, не переводя их на счет Принципала, до полного оказания Услуги в пользу
        Клиента.</p>
      <p>3.3.3. При наличии жалобы Клиента на действия Принципала, потребовать у Принципала видеозапись Занятия, а также
        приостановить расчеты с Принципалом до окончания разбирательства по жалобе.</p>
      <p>3.3.4. Заключать договоры с банками, клиринговыми центрами, операторами платежных систем и иными лицами в целях
        размещения на Сайте предоставления Принципалу услуг по расчетам. Агент не отвечает за действия банка, либо иного
        лица, предоставляющего сервис по проведению расчетов. </p>
      <p>3.3.5. Проводить профилактические работы на Сайте, которые могут повлечь временное приостановление работы
        Сайта.</p>
      <p>3.3.6. Удалить аккаунт Принципала в случае: </p>
      <p>● нарушения условий Договора, установленных в п.3.2 и п.3.5 Договора; </p>
      <p>● если аккаунт не используется более 365 календарных дней подряд; </p>
      <p>● Агент установил несоответствие Принципала требованиям к Ментору/Специалисту, установленным Договором или
        размещенным на Сайте;</p>
      <p>● Агент получил информацию о том, что Принципал предлагает Клиентам занятия и/или оплату вне Сайта.</p>
      <p>3.3.7. Удаление аккаунта производится после проведения Ментором всех Занятий, оплата за которые на текущую дату
        получена от Клиентов.</p>
      <p>3.3.8. В случае удаления аккаунта Принципала в связи с нарушениями условий Договора, Агент оставляет за собой
        право отказать Принципалу в повторной Регистрации на Сайте.</p>
      <p>3.3.9. Осуществлять аудио/видеосъемку Занятия как полностью, так и частично, осуществлять хранение, обработку,
        передачу третьим лицам аудио- и видеозаписей, а также использовать их в своих интересах, например в рекламных
        целях для продвижения Сайта в сети Интернет, без получения на это согласия Принципала. </p>
      <p>3.3.10. В одностороннем порядке без предварительного уведомления изменять, дорабатывать и обновлять Сайт, а
        также вносить изменения в условия Договора. Изменения вступают в силу с момента опубликования новой версии
        Договора на Сайте в разделе по ссылке. Принципал самостоятельно проверяет условия Договора на предмет их
        изменения и/или дополнения. Использование Сайта Принципалом после внесения изменений в Договор означает согласие
        Принципал с такими изменениями.</p>
      <p>3.3.10. В одностороннем порядке без предварительного уведомления изменять, дорабатывать и обновлять Сайт, а
        также вносить изменения в условия Договора. Изменения вступают в силу с момента опубликования новой версии
        Договора на Сайте в разделе по ссылке. Принципал самостоятельно проверяет условия Договора на предмет их
        изменения и/или дополнения. Использование Сайта Принципалом после внесения изменений в Договор означает согласие
        Принципал с такими изменениями.</p>
      <p>3.4. Принципал вправе:</p>
      <p>3.4.1. Получать Отчеты Агента о выполнении поручений Принципала.</p>
      <p>3.4.2. Заключать с Клиентами договоры оказания консультационных услуг и требовать от Клиентов их
        исполнения.</p>
      <p>3.4.3. Пользоваться сервисами, размещенными на Сайте, включая возможность получения Платежей от Клиентов за
        проведенные Занятия.</p>
      <p>3.5. Принципалу запрещается:</p>
      <p>3.5.1. Использовать Сайт в иных, прямо не предусмотренных Договором, целях.</p>
      <p>3.5.2. Любое использование размещенных на Сайте материалов (включая воспроизведение и распространение их любыми
        способами, в том числе в интернете, публичный показ, доведение до всеобщего сведения, копирование (полное или
        частичное), переработка и пр.) запрещены, за исключением случаев, когда Агент предоставил предварительное
        письменное согласие на такие действия.</p>
      <p>3.5.3. Размещать на Сайте недостоверные сведения, клевету и оскорбления, ненормативную лексику, сведения
        экстремистского характера, ссылки на сайты и страницы в Интернете, содержание которых противоречит
        законодательству и целям настоящего Договора.</p>
      <p>3.5.4. Размещать на Сайте информацию, которая нарушает авторские права третьих лиц.</p>
      <p>3.5.5. Предоставлять доступ к Личному кабинету третьему лицу. Агент не несет ответственности за утерю или
        утрату по любым причинам Принципалом своих регистрационных и иных сведений, в том числе по причине мошеннических
        или иных противоправных действий третьих лиц.</p>
      <p>3.5.5. Предоставлять доступ к Личному кабинету третьему лицу. Агент не несет ответственности за утерю или
        утрату по любым причинам Принципалом своих регистрационных и иных сведений, в том числе по причине мошеннических
        или иных противоправных действий третьих лиц.</p>
      <p>3.5.7. Указывать банковские реквизиты третьего лица для перечисления Агентом причитающихся Принципалу денежных
        средств.</p>
      <p>3.5.8. Использовать Сайт для распространения информации рекламного характера, без согласия Агента. </p>
      <p>3.5.9. Осуществлять несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к
        данному Сайту, а также к любым сервисам Сайта.</p>
      <p>3.5.10. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом
        Принципале либо Пользователе Сайта.</p>
      <p>3.5.11. Принимать оплату за Занятие без использования функционала Сайта.</p>
      <p>3.5.12. Предлагать на Сайте Услуги, не предусмотренные Договором.</p>
      <p>3.5.13. Рекомендовать Клиентам отказаться от использования Сайта. </p>
      <p>3.5.14.Совершать действия, которые могут порочить деловую репутацию создателей Сайта, либо Агента, повлечь
        причинение причинить ущерба Агенту.</p>
      <p>3.5.15. Предоставлять о себе недостоверные сведения.</p>
      <p>3.6. Агент осуществляет прием платежей от Клиента при условии, если при осуществлении платежа Клиентом указана
        информация, позволяющая однозначно идентифицировать Клиента и Принципала.</p>
      <p>3.7. Размещая на Сайте информацию и фотографии, Принципал делает сведения, указанные в Профиле, общедоступными
        и осознает, что размещенная информация публикуется на Сайте в открытом доступе, таким образом информация
        становится доступной для ознакомления неограниченному кругу лиц на территории всех стран мира, где имеется
        возможность использования сети Интернет и доступа к Сайту. Агент не несет ответственности за сведения,
        предоставленные Принципалом на Сайте в общедоступной форме.</p>
      <p>3.8. Предоставляя свои персональные данные, Принципал соглашается на их обработку, в том числе в целях
        выполнения Агентом своих обязательств. Под обработкой персональных данных понимается любое действие (операция)
        или совокупность действий (операций), совершаемых Агентом с использованием средств автоматизации или без
        использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение) извлечение, использование, обезличивание, блокирование, удаление, уничтожение
        персональных данных.</p>
      <p>3.9. Рассмотрение жалоб Клиента.</p>
      <p>3.9.1. Клиент вправе в срок не более 24 (двадцати четырех) часов с момента окончания Занятия направить Агенту
        жалобу на действия Принципала.</p>
      <p>3.9.2. Агент вправе отказаться от рассмотрения жалобы Клиента без объяснения причин. </p>
      <p>3.9.3. В случае, если Агент принимает жалобу Клиента к рассмотрению, после получения жалобы Клиента Агент
        запрашивает любым из предусмотренных Договором способом видеозапись указанного Занятия у Принципала, если
        последний ранее не направил ссылку на указанную видеозапись Агенту.</p>
      <p>3.9.4. Принципал в срок не более двух календарных дней после получения от Агента соответствующего запроса
        предоставляет видеозапись Агенту в виде ссылки на видеозапись в Личном кабинете, либо путем направления на
        электронную почту Принципала;</p>
      <p>3.9.5. В течение 5 (пяти) рабочих дней момента получения жалобы Клиента Агент принимает решение по спорной
        ситуации. В случае признания жалобы Клиента обоснованной, Агент направляет Принципалу требование о возврате
        оплаты Занятия Клиенту. В случае отклонения жалобы Клиента, оплата Занятия не возвращается. О принятом решении
        Агент уведомляет Принципала и Клиента через чат на Сайте.</p>
      <p>3.9.6. В случае не предоставления Принципалом видеозаписи занятия в течение двух календарных дней с момента
        направления запроса Агента с требованием предоставить видеозапись Занятия, денежные средства за Занятие подлежат
        возврату Клиенту.</p>
      <p>3.9.7. В случае, когда Принципал обязан вернуть оплату за Занятие согласно п.3.9.5 и п.3.9.6, такой возврат
        осуществляется Принципалом в течение не более 5 (пяти) рабочих дней с момента получения соответствующего
        требования Агента.</p>
      <p>3.9.8. При совершении Принципалом одного нарушения, признанного Агентом обоснованным, Агент без
        предварительного уведомления по своему выбору вправе:</p>
      <p>● заблокировать доступ Принципала к Сайту (по усмотрению Агента временно либо постоянно);</p>
      <p>● в одностороннем внесудебном порядке отказаться от исполнения Договора;</p>
      <p>● отказать Принципалу в сотрудничестве и в повторной Регистрации на Сайте.</p>
      <p></p>
      <h2>4. АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ </h2>
      <p>4.1. Агентское вознаграждение удерживается Агентом из суммы Платежа. В случае недостаточности средств на
        удержание, Агентское вознаграждение подлежит выплате Принципалом не позднее, чем в течение 5 (пяти) рабочих дней
        со дня получения от Агента соответствующего требования, либо счета на оплату.</p>
      <p>4.2. Агентское вознаграждение НДС не облагается в соответствии с п. 3 ст. 346.11 Налогового кодекса Российской
        Федерации.</p>
      <p>4.3. Размер Агентского вознаграждения составляет 25 % (двадцать пять) процентов от стоимости Услуг
        Принципала.</p>
      <p>4.4. Размер Агентского вознаграждения входит в цену Занятия, установленную Агентом для Клиента. </p>
      <p>4.5. Клиент оплачивает цену Занятия путем перечисления Платежа на расчетный счет Агента. Непосредственно после
        внесения Платежа на личном счете Клиента отображается сумма внесенных денежных средств.</p>
      <p>4.6. Обязательства Клиента по оплате услуг Принципала считаются исполненными с момента поступления денежных
        средств Агенту. </p>
      <p>4.7. Агент обязан в течение 1 (одного) рабочего дня после завершения Занятия перечислить на расчетный счет
        Принципала все полученные от Клиента денежные средства за вычетом суммы агентского вознаграждения.</p>
      <p>4.8. Моментом исполнения обязанности Агента по перечислению денежных средств Принципалу является дата списания
        денежных средств с расчетного счета Агента. Срок зачисления денежных средств зависит от банка-эмитента
        Принципала.</p>
      <p>4.9. Принципал дает Агенту право требовать от Клиента оплаты Занятия, а также требовать от Клиента исполнения
        иных обязательств, установленных Договором и договором оказания консультационных услуг. </p>
      <p>4.10. Агент не несет ответственности за качество услуг, оказываемых Принципалом. </p>
      <p>4.11. В соответствии с абз. 3 ч. 1 ст. 1005 Гражданского кодекса РФ Принципал самостоятельно несет
        ответственность перед Клиентом за ненадлежащее качество оказания услуг перед Клиентом в рамках договора на
        оказание консультационных услуг.</p>
      <p>4.12. Принципал поручает Агенту выступать в качестве арбитра в споре между ним и Клиентом в отношении качества
        оказанных Принципалом Услуг и в отношении соблюдения правил оказания Услуг, предусмотренных Договором,
        Лицензионным договором и договором оказания консультационных услуг.</p>
      <p>4.13. При этом Агент вправе по своему усмотрению отказаться от исполнения функции арбитра в споре между
        Принципалом и Клиентом. В этом случае спор должен быть разрешен путем переговоров между Принципалом и Клиентом,
        а в случае недостижения соглашения, путем обращения в суд или третейский суд с соответствующим иском.</p>
      <p>4.13. При этом Агент вправе по своему усмотрению отказаться от исполнения функции арбитра в споре между
        Принципалом и Клиентом. В этом случае спор должен быть разрешен путем переговоров между Принципалом и Клиентом,
        а в случае недостижения соглашения, путем обращения в суд или третейский суд с соответствующим иском.</p>
      <p></p>
      <h2>5. ЗАЩИТА РЕЗУЛЬТАТОВ ИНТЕЛЛЕКТУАЛЬНОЙ ДЕЯТЕЛЬНОСТИ</h2>
      <p>5.1 Агент гарантирует, что он является владельцем исключительных прав на Сайт. </p>
      <p>5.2 К результатам интеллектуальной деятельности и средствам индивидуализации, входящих в состав Сайта относятся
        содержание Сайта, сервисы и сам Сайт, его элементы, текст, графические изображения, иллюстрации, размещенные
        материалы, дизайн, программное обеспечение, и иные элементы (инструменты, алгоритмы, способы, исходный код),
        необходимые для надлежащего функционирования Сайта, а также любые иные возможности и функции, доступные на
        Сайте. Авторское право на контент Сайта, в т.ч. видеозаписи консультаций и иные текстовые, графические, аудио-,
        видео- материалы размещенные на сайте принадлежит Агенту, если иное не установлено законом, Договором, либо
        соглашением Агента и автора произведения. Право авторства, то есть право признаваться автором произведения и
        право автора на имя в отношении видеозаписей консультаций принадлежит Ментору.</p>
      <p>5.3 Использование результатов интеллектуальной деятельности и средств индивидуализации, входящие в состав
        Сайта, воспроизведение и распространение их любыми способами, в том числе в интернете, публичный показ,
        доведение до всеобщего сведения, копирование (полное и частичное), переработка, воспроизведение, переработка,
        отображение во фрейме, опубликование, скачивание, передача, продажа или использование иным способом целиком или
        по частям без предварительного разрешения правообладателя, без согласия Агента запрещено, за исключением
        случаев, прямо предусмотренных Договором, либо договором на оказание консультационных услуг.</p>
      <p>5.4 Никакие положения Договора не предоставляют Принципалу прав на использование фирменного наименования,
        коммерческого обозначения, товарных знаков, доменных имен и иных отличительных знаков Агента.</p>
      <p>5.5 При размещении Принципалом результатов интеллектуальной деятельности на Сайте, Принципал безвозмездно на
        условиях простой неисключительной лицензии предоставляет Агенту право использования, воспроизведения,
        распространения, переработки, создания производных произведений, а также на демонстрацию произведений или его
        частей и доведение их до всеобщего сведения. Принципал предоставляет указанные права на весь срок действия
        исключительного права.</p>
      <p>5.6 Расторжение Договора не является основанием для прекращения указанных прав на использование результатов
        интеллектуальной деятельности Принципала на условиях простой неисключительной лицензии.</p>
      <p>5.7 Агент не несет ответственности за незаконное использование результатов интеллектуальной деятельности
        Принципала, размещенных в открытом доступе на Сайте.</p>
      <p>5.8 Агент может, но не обязан, просматривать информацию, размещаемую Принципалом на Сайте, на наличие
        запрещенного контента, и может удалять (без предупреждения) любой контент, который, по мнению Агента, нарушает
        Договор, законодательство Российской Федерации и/или может нарушать права, причинить вред или угрожать
        безопасности других пользователей Сайта или третьих лиц.</p>
      <p>5.9 Сайт может содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц) так же, как и статьи,
        фотографии, иллюстрации, графические изображения, информацию, приложения, программы и другой контент,
        принадлежащий или исходящий от третьих лиц (контент третьих лиц), являющийся результатом интеллектуальной
        деятельности и охраняемый в соответствии с законодательством Российской Федерации. Указанные третьи лица и их
        контент не проверяются Агентом на соответствие тем или иным требованиям (достоверности, полноты,
        добросовестности и т.п.). Агент не несет ответственность за любую информацию, размещенную на сайтах третьих лиц,
        к которым Агент получает доступ через Сайт или через контент третьих лиц.</p>
      <p>5.10 Размещенные на Сайте ссылки на любой сайт, продукт, услугу, любую информацию коммерческого или
        некоммерческого характера, не является одобрением или рекомендацией данных продуктов (услуг) со стороны
        Агента.</p>
      <p>5.11 Размещенные на Сайте ссылки или руководства по скачиванию файлов и(или) установке программ третьих лиц не
        означают поддержки или одобрения этих действий со стороны Агента. Если Принципал решил перейти к сайтам третьих
        лиц или использовать, или установить программы третьих лиц, он делает это на свой риск. </p>
      <p>5.12 В отношении объектов интеллектуальной собственности, принадлежащих третьим лицам и размещенных на Сайте,
        обязательства Агента регулируются положениями статьи 1253.1 Гражданского кодекса Российской Федерации, в
        терминах которой Агент является информационным посредником.</p>
      <p></p>
      <h2>6. ВЗАИМОДЕЙСТВИЕ ПРИНЦИПАЛА И КЛИЕНТА</h2>
      <p>6.1. После опубликования информации о Принципале (Менторе/Специалисте) на Сайте, сведения о Принципале
        (Менторе/Специалисте) вносятся в список Менторов, размещенный на Сайте. </p>
      <p>6.2. Клиент выбирает Ментора/Специалиста из списка Менторов или из списка Специалистов, размещенных на Сайте. В
        Профиле Ментора/Специалиста отображена стоимость его Услуг. При согласии со стоимостью Услуг, датой и временем
        оказания Услуги, Клиент нажимает кнопку «Забронировать». Общение между Ментором и Менти осуществляется
        исключительно через Личные кабинеты на Сайте. </p>
      <p>6.3. Безусловным акцептом Клиента договора оказания консультационных услуг является факт внесения оплаты в
        размере стоимости Услуг Принципала.</p>
      <p>6.4. Все обязательства по проведению Занятия возникают непосредственно у Принципала. Агент не является стороной
        взаимоотношений Принципала и Клиента по оказанию консультационных услуг.</p>
      <p>6.5. Доступ к Занятию предоставляется Клиенту и Принципалу на срок сохранения регистрации Профиля на
        Сайте. </p>
      <p>6.6. Агент не предоставляет Принципалу и Клиенту рабочее место, оборудование, какие-либо материалы для
        исполнения договора об оказании консультационных услуг, не утверждает порядок проведения Занятия.</p>
      <p></p>
      <h2>7. ПЛАТЕЖИ</h2>
      <p>7.1. Как следует из Федерального закона от 27.06.2011 № 161-ФЗ «О национальной платежной системе», Агент не
        является оператором по переводу денежных средств, оператором электронных денежных средств, банковским платежным
        агентом (субагентом), платежным агентом, оператором платежных систем, оператором услуг платежной
        инфраструктуры.</p>
      <p>7.2. Поступление оплаты от Клиента за Занятие, за вычетом Агентского вознаграждения, отражается в Личном
        кабинете Принципала.</p>
      <p>7.3. Агент вправе устанавливать скидки от объема приобретаемых Занятий, начислять Клиентам бонусы </p>
      <p>7.4. Клиент вправе отказаться от проведения Занятия, не позднее, чем за одни календарные сутки до согласованной
        с Принципалом даты и времени проведения Занятия. </p>
      <p>7.5. Внесенная оплата Занятия в этом случае подлежит возврату Клиенту в срок не позднее 1 (одного) рабочего дня
        после получения Агентом заявления Клиента об отказе от проведения Занятия. </p>
      <p>7.6. В случае, если Клиент отказывается от проведения Занятия позднее, чем за одни календарные сутки до
        согласованной с Принципалом даты и времени проведения Занятия, он выплачивает Принципалу компенсацию рабочего
        времени в размере ½ (одной второй) доли от стоимости Занятия. Компенсация удерживается Агентом из суммы Платежа
        за Занятие. При этом Агент сохраняет право на получение агентского вознаграждения, исходя из полной стоимости
        Занятия.</p>
      <p>7.7. Если Клиент отказался от проведения Занятия, но по каким-либо причинам не имеет возможность принять
        участие в Занятии, оплата за Занятие ему не возвращается. </p>
      <p>7.8. В отсутствие оплаты занятие Ментором не проводится.</p>
      <p></p>
      <h2>8. ИСПОЛНЕНИЕ АГЕНТСКОГО ДОГОВОРА </h2>
      <p>8.1 Отчетными месяцами являются те месяцы, в которые Клиентами осуществлялись перечисления денежных средств для
        оплаты Услуг Принципала.</p>
      <p>8.2 По окончании отчетного месяца Агент направляет Принципалу отчет Агента посредством размещения в Личном
        кабинете в течение десяти календарных дней после завершения отчетного месяца.</p>
      <p>8.3 Отчет Агента предоставляется в свободной форме и должен содержать сведения о количестве проведенных
        Принципалом Занятий, размере денежных средств, уплаченных Клиентами за занятия на основании договоров на
        оказание консультационных услуг, заключенных с Принципалом, размере Агентского вознаграждения. </p>
      <p>8.4 В срок не позднее 3 (трех) рабочих дней с размещения отчета Агента в Личном кабинете, Принципал вправе
        направить свои письменные мотивированные возражения на отчет Агента. При отсутствии возражений, отчет считается
        принятым Принципалом без замечаний и претензий.</p>
      <p></p>
      <h2>9. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
      <p>9.1. За использование Сайта способом, не предусмотренным Договором, либо после прекращения действия Договора,
        либо иным образом за пределами прав, предоставленных Договором, Принципал несет ответственность за нарушение
        исключительного права на Сайт, предусмотренную Гражданским кодексом РФ и другими нормативно-правовыми
        актами.</p>
      <p>9.2 Принципал понимает и соглашается с тем, что Сайт предоставляется «как есть», и что Агент не несет
        ответственности ни за какие задержки, сбои в работе Сайта, неверную или несвоевременную доставку сообщений
        отправленных Принципалом, Агентом, Клиентом, за удаление или не сохранность какой-либо пользовательской
        персональной или иной информации, если иное не установлено Договором, иными соглашениями или законодательством
        Российской Федерации. При этом, Агент обязуется принимать все разумные меры для предотвращения таких
        перебоев.</p>
      <p>9.2 Принципал понимает и соглашается с тем, что Сайт предоставляется «как есть», и что Агент не несет
        ответственности ни за какие задержки, сбои в работе Сайта, неверную или несвоевременную доставку сообщений
        отправленных Принципалом, Агентом, Клиентом, за удаление или не сохранность какой-либо пользовательской
        персональной или иной информации, если иное не установлено Договором, иными соглашениями или законодательством
        Российской Федерации. При этом, Агент обязуется принимать все разумные меры для предотвращения таких
        перебоев.</p>
      <p>9.4 Агент не несет ответственности за невозможность использования Сайта по не зависящим от него причинам.</p>
      <p>9.5 Принципал несет ответственность за размещение на Сайте информации и контента от своего имени.</p>
      <p>9.6 Ответственность за осуществление расчетов несет банк-партнер, предоставляющий Сторонам услуги по проведению
        расчетов. </p>
      <p>9.7 Агент несет ответственность:</p>
      <p>9.7.1 За правильность отображения остатков денежных средств в личном кабинете Принципала;</p>
      <p>9.7.2 За правильность расчета и начисления комиссий, бонусов, скидок. </p>
      <p>9.8 Агент не несет ответственности при осуществлении противоправных действий третьих лиц с целью получения
        несанкционированного доступа к функциям Сайта, любым другим системам или сетям, относящимся к Сайту, а также к
        любым сервисам Сайта. В случае поступления сообщений об указанных действиях третьих лиц, Агент примет все
        возможные меры для скорейшего разрешения указанных обстоятельств.</p>
      <p>9.9 Агент вправе ограничить доступ к видеозаписям Занятий до истечения срока предоставления доступа,
        установленного п.6.5, в случае допущения Принципалом нарушения условий Договора, либо договора оказания
        консультационных услуг.</p>
      <p>9.10 Стороны освобождаются от ответственности в случае, если неисполнение или ненадлежащее исполнение ими своих
        обязательств по Договору будет связано с обстоятельствами, не зависящими от их воли: природных явлений
        (землетрясений, наводнений, эпидемий), действий государственных органов, актов террора, войн и т. д.</p>
      <p>9.11 Сторона обязана незамедлительно после наступления указанных в п.9.10 обстоятельств уведомить другую
        Сторону о невозможности исполнения Договора и подтвердить документально факт наступления указанных
        обстоятельств. Неисполнение условий настоящего пункта влечет для Сторон обязанность по возмещению убытков,
        связанных с неисполнением условий Договора.</p>
      <p></p>
      <h2>10. РАЗРЕШЕНИЕ СПОРОВ</h2>
      <p>10.1 Принципал самостоятельно урегулирует предъявленных к Принципалу претензии Клиентов и третьих лиц
        относительно Услуг. В установленных Договором случаях Принципал дает поручение Агенту выступать в роли арбитра
        при разрешении споров, возникших из договора оказания консультационных услуг.</p>
      <p>10.2 Принципал обязуется возместить убытки Агента, которые возникли в связи с неправомерными или
        противоправными действиями Принципала.</p>
      <p>10.3 Все споры и разногласия, которые могут возникнуть между Сторонами и вытекающие из Договора или в связи с
        ним, будут разрешаться путем переговоров. В случае невозможности путем переговоров достичь соглашения по спорным
        вопросам в течение 15 (пятнадцати) календарных дней с момента направления письменной претензии, споры
        разрешаются в суде по месту нахождения Агента.</p>
      <p></p>
      <h2>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
      <p>11.1 Договор вступает в силу с момента его акцепта и действует до удаления Личного кабинета Ментора.</p>
      <p>11.2 Все уведомления, сообщения и переписка Сторон происходит посредством электронной связи (посредством сети
        Интернет, с использованием Сайта, электронной почты). Такая переписка признается Сторонами, в качестве
        письменных доказательств, как они определены в ст. 75 АПК РФ и ст.71 ГПК РФ. </p>
      <p>11.3 В случае обмена Сторон документами посредством электронной связи, направление документов на бумажных
        носителях не требуется. Документы и информация, направленные Сторонами друг другу по электронной почте либо
        посредством Сайта, считаются полученными в рабочий день, следующий за днем отправки таких документов или
        информации.</p>
      <p>11.4 Стороны считают надлежащим способом для переписки обмен электронными письмами по адресу электронной почты
        Агента:
        <a href="mailto:hello@mentoring.digital">hello@mentoring.digital</a>.</p>
      <p>11.5 Надлежащим извещением Принципала будет считаться отправление соответствующей информации на электронную
        почту, указанную при регистрации Принципала на Сайте.</p>
      <p>11.6 Стороны признают, что любая без исключения деловая корреспонденция, отправленная с адресов электронной
        почты, является исходящей от надлежащим образом уполномоченных представителей Сторон и имеет обязательную для
        Сторон силу.</p>
      <p>11.7 Принципал обязуется поддерживать актуальность размещенной в учетной записи информации и извещать Агента об
        изменении реквизитов, в том числе банковских, почтовых, электронных адресов, номеров телефонов, в течение 3
        (трех) календарных дней с даты изменения. В случае неисполнения Принципалом, указанного требования все
        извещения, уведомления, направленные по известным Агенту реквизитам, считаются надлежаще направленными.</p>
      <p>11.8 По всем вопросам Принципал вправе обращаться через сервисную поддержку Сайта путем направления сообщений,
        уведомлений, жалоб и прочих вопросов на электронную почту <a href="mailto:hello@mentoring.digital">hello@mentoring.digital</a>,
        через Личный кабинет, либо
        связаться по телефону +7 499 643-46-50.</p>
      <p>11.9 Сайт может быть в то или иное время частично или полностью недоступен по причине проведения
        профилактических или иных работ, или по любым другим причинам технического характера, но не более 4 (четырех)
        часов подряд. Агент имеет право проводить необходимые профилактические или иные работы с предварительным
        уведомлением Принципала или без такового. </p>
      <p>11.10 Персональные данные Пользователей обрабатывается в соответствии с Федеральным законом от 27 июля 2006 г.
        N 152-ФЗ «О персональных данных». </p>
      <p>11.11 Обработка персональных данных Пользователей осуществляется в соответствии с законодательством Российской
        Федерации в порядке, указанном в Политике в отношении обработки персональных данных, размещенной по ссылке
        <a href="https://mentoring.digital/privacy">https://mentoring.digital/privacy</a>. </p>
      <p>11.12 Сайт использует технологию «cookies».</p>
      <p>11.13 Агент получает информацию об IP-адресе посетителя Сайта. Данная информация не используется для
        установления личности посетителя.</p>
      <p>11.14 Агент не несет ответственности за сведения, предоставленные Принципалом на Сайте в общедоступной
        форме.</p>
      <p>11.15 Договор регулируется и подлежит толкованию в соответствии с нормами законодательства Российской
        Федерации.</p>
      <p>11.16 Переход исключительного права на Сайт к новому правообладателю не является основанием для изменения или
        расторжения Договора.</p>
      <p>11.17 Заявления, уведомления, извещения, требования или иные юридически значимые сообщения, с которыми Договор
        связывает гражданско-правовые последствия для Сторон, влекут для этого лица такие последствия с момента доставки
        соответствующего сообщения Стороне или ее представителю.</p>
      <p>11.18 Во всем остальном, что не предусмотрено Договором, Стороны руководствуются законодательством Российской
        Федерации.</p>
    </div>
  </main>
</template>

<script setup>
useHead({
  title: 'Оферта'
})
</script>

<style scoped>
.page-title {
  margin-bottom: 16px;
}

.offer-subtitle {
  text-align: right;
}

h2 {
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
}

ul {
  list-style: initial;
  padding-left: 20px;
}

li {
  list-style: initial;
}

p {
  margin: 5px 0;
}
</style>
